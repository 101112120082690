import { Ref, ref } from 'vue';

import { resolveNumber } from '@/common/formatter';
interface Select {
  label: string;
  value: number;
}

export interface GrpTick {
  grpTickValue: Ref<Select>;
  grpTickOptions: Ref<Select[]>;
}

export const useGrpTick = ({ init }: { init: number[] }): GrpTick => {
  const grpTickValue = ref({} as Select);
  const grpTickOptions = ref([] as Select[]);
  grpTickOptions.value = [...Array(8)].map((_, i) => ({
    label: `${resolveNumber((i + 1) * 500)}`,
    value: (i + 1) * 500
  }));
  grpTickValue.value = grpTickOptions.value[3];
  // 初期化
  if (init) {
    const last = init.slice(-1)[0];
    grpTickValue.value = grpTickOptions.value.filter(v => v.value === last)[0];
  }
  return {
    grpTickValue,
    grpTickOptions
  };
};
