import { Ref, ref } from 'vue';
import { StationPlacementPattern } from '@/api/openapi';
export interface StationPlacementPatterns {
  stationPlacementPatternsValue: Ref<StationPlacementPattern[]>;
}

export const useStationPlacementPatterns = ({
  init
}: {
  init: StationPlacementPattern[];
}): StationPlacementPatterns => {
  const stationPlacementPatternsValue = ref([] as StationPlacementPattern[]);
  // 初期値
  if (init) {
    stationPlacementPatternsValue.value = init;
  }
  return {
    stationPlacementPatternsValue
  };
};
