import { ref, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAreaOptions } from '@/store/areaOptions';
import { CmsiApi } from '@/api';
import {
  AreaInfoIdEnum,
  SelectableAreasList,
  CmSalesImpactAvailableArea
} from '@/api/openapi';
import { useRoute } from 'vue-router';
import useLoading from '@/composables/loading';

import { toast } from '@/components/ui/Toast';

// エリア
export interface Area {
  areaValue: Ref<AreaInfoIdEnum>;
  areaSelectOptions: Ref<SelectableAreasList[]>;
  isLoadingAreaStore: Ref<boolean>;
}
interface Props {
  companyId: number;
  productId: number;
  init?: AreaInfoIdEnum;
}
export interface SimpleArea {
  areaValue: Ref<AreaInfoIdEnum | 'CMSI_ALL'>;
  areaList: Ref<{ label: string; value: AreaInfoIdEnum | 'CMSI_ALL' }[]>;
  isLoadingArea: Ref<boolean>;
  getArea: (Props) => void;
}

export const useArea = (): Area => {
  const areaOptionStore = useAreaOptions();
  const route = useRoute();

  const {
    isLoading: isLoadingAreaStore,
    selectOptions: areaSelectOptions
  } = storeToRefs(areaOptionStore);

  const areaValue = ref(areaSelectOptions.value[0].areas[0].id);

  (async () => {
    const data = await areaOptionStore.fetch(route);
    if (data) {
      areaValue.value = data;
    }
  })();

  return {
    areaValue,
    areaSelectOptions,
    isLoadingAreaStore
  };
};

export const useSimpleArea = ({ init }: { init: string }): SimpleArea => {
  const areaList = ref(
    [] as { label: string; value: AreaInfoIdEnum | 'CMSI_ALL' }[]
  );
  const areaValue = ref<AreaInfoIdEnum | 'CMSI_ALL'>('CMSI_ALL');
  const [isLoading, fetch] = useLoading(_fetch);

  isLoading.value = true;
  const get = async (props: Props) => {
    const res = await fetch(props);
    areaList.value = res.map(v => ({
      value: v.areaCode as AreaInfoIdEnum,
      label: v.areaName
    }));
    if (res.length > 1) {
      areaList.value.unshift({
        value: 'CMSI_ALL',
        label: '全エリア'
      });
    }
    if (res.length === 1) {
      areaValue.value = res[0].areaCode as AreaInfoIdEnum;
    } else {
      areaValue.value = 'CMSI_ALL';
    }

    // 初期化
    if (init) {
      if (init.indexOf('・') > 0) {
        areaValue.value = 'CMSI_ALL';
      } else {
        const current = areaList.value.filter(v => v.label === init);
        if (current[0]) {
          areaValue.value = current[0].value as 'CMSI_ALL';
        }
      }
    }
  };

  return {
    areaList,
    areaValue,
    isLoadingArea: isLoading,
    getArea: get
  };
};
const _fetch = async ({
  companyId,
  productId
}): Promise<CmSalesImpactAvailableArea[]> => {
  try {
    const { data } = await CmsiApi.getCmSalesImpactAvailableAreas(
      companyId,
      productId
    );
    return data;
  } catch (e) {
    toast({
      title: 'エリア取得の失敗',
      message: e.message,
      variant: 'error'
    });
    return [] as CmSalesImpactAvailableArea[];
  }
};
