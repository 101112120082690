import { ref, Ref } from 'vue';
interface Select {
  label: string;
  value: number;
}
export interface ReachWeek {
  reachWeekValue: Ref<Select>;
  optionsReachWeek: Ref<Select[]>;
}

export interface ReachFrequency {
  reachFrequencyValue: Ref<Select>;
  optionsReachFrequency: Ref<Select[]>;
}

// リーチ基準
export const useReachWeek = ({ init }: { init?: number }): ReachWeek => {
  const reachWeekValue = ref({} as Select);
  const optionsReachWeek = ref([] as Select[]);
  optionsReachWeek.value = [...Array(4)].map((_, i) => ({
    label: `${i + 1}週間`,
    value: i + 1
  }));

  // 初期化
  reachWeekValue.value = init
    ? optionsReachWeek.value.filter(v => v.value === init)[0]
    : optionsReachWeek.value[1];

  return {
    reachWeekValue,
    optionsReachWeek
  };
};
export const useReachFrequency = ({
  init
}: {
  init?: number;
}): ReachFrequency => {
  const reachFrequencyValue = ref({} as Select);
  const optionsReachFrequency = ref([] as Select[]);
  optionsReachFrequency.value = [...Array(10)].map((_, i) => ({
    label: `${i + 1}回`,
    value: i + 1
  }));
  reachFrequencyValue.value = optionsReachFrequency.value.filter(
    v => v.value === init
  )[0];
  // 初期化
  reachFrequencyValue.value = init
    ? optionsReachFrequency.value.filter(v => v.value === init)[0]
    : optionsReachFrequency.value[1];

  return {
    reachFrequencyValue,
    optionsReachFrequency
  };
};
