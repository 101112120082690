import { Ref, ref } from 'vue';

interface Select {
  label: string;
  value: number;
}
export interface CampaignWeeks {
  campaignWeeksValue: Ref<Select>;
  campaignWeeksOptions: Ref<Select[]>;
}

export const useCampaignWeeks = ({
  init
}: {
  init: number[];
}): CampaignWeeks => {
  const campaignWeeksValue = ref({} as Select);
  const campaignWeeksOptions = ref([] as Select[]);
  campaignWeeksOptions.value = [...Array(8)].map((_, i) => ({
    label: `${i + 1}週間`,
    value: i + 1
  }));
  // 初期化
  campaignWeeksValue.value = init
    ? campaignWeeksOptions.value.filter(
        v => v.value === init[init.length - 1]
      )[0]
    : campaignWeeksOptions.value[3];
  return {
    campaignWeeksOptions,
    campaignWeeksValue
  };
};
