import { ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { COMPANY_ROUTES, CMSIS_ROUTES } from '@/router';

import { CmSalesImpactSimulatorResultCmsisSimulationStatusEnum } from '@/api/openapi';
import { Project } from '@/composables/cmsis/store';
import { _fetch } from '@/composables/cmsis/result';

import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    {
      name: '',
      label: 'CMセールスインパクト'
    },
    {
      name: CMSIS_ROUTES.index,
      label: '売上効果シミュレーター'
    }
  ],
  current: { label: 'プロジェクトの編集' }
};
interface Edit {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  pageTitle: Ref<string>;
  status: Ref<CmSalesImpactSimulatorResultCmsisSimulationStatusEnum>;
  isLoading: Ref<boolean>;
}

export const useEdit = (project: Project): Edit => {
  const router = useRouter();

  const pageTitle = ref('');
  const breadcrumbs = ref(BREADCRUMBS);
  const status = ref(
    '' as CmSalesImpactSimulatorResultCmsisSimulationStatusEnum
  );

  (async () => {
    if (project.cmsisProjectTitle) {
      // タイトルの書き換え
      pageTitle.value = `${project.cmsisProjectTitle} プロジェクトの編集`;
    }
  })();

  // レポートの結果を取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    const { cmsisSimulationStatus } = await fetch({
      cmsisProjectId: project.cmsisProjectId
    });
    // 集計中は一覧に移動
    if (
      cmsisSimulationStatus ===
        CmSalesImpactSimulatorResultCmsisSimulationStatusEnum.Pending ||
      cmsisSimulationStatus ===
        CmSalesImpactSimulatorResultCmsisSimulationStatusEnum.InProgress
    ) {
      toast({
        title: 'シミュレーション集計中のため一覧に移動しました。',
        variant: 'error'
      });
      await router.push({
        name: CMSIS_ROUTES.index
      });
    } else {
      status.value = await cmsisSimulationStatus;
    }
  })();

  return {
    breadcrumbs,
    pageTitle,
    status,
    isLoading
  };
};
