import { computed, ComputedRef, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { COMPANY_ROUTES, CMSIS_ROUTES } from '@/router';

import { Project } from '@/composables/cmsis/store';
import { CmsiApi, CmsisApi } from '@/api';
import {
  CmSalesImpactSimulatorProject,
  CmSalesImpactSimulatorProjectForm,
  //
  AreaInfoIdEnum,
  CmSalesImpactProducts
} from '@/api/openapi';

import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { useStore } from './store';

import {
  useSimpleArea,
  useCmProducts,
  usePerCost,
  useReachWeek,
  useReachFrequency,
  useDuration,
  useStationPlacementPatterns,
  useCampaignWeeks,
  useGrpTick,
  useSeparate
} from './inputs';
import { SimpleArea } from './inputs/useArea';
import { CmProducts } from './inputs/useCmProducts';
import { PerCost } from './inputs/usePerCost';
import { ReachWeek, ReachFrequency } from './inputs/useReachBasic';
import { Duration } from './inputs/useDuration';
import { StationPlacementPatterns } from './inputs/useStationPlacementPatterns';
import { CampaignWeeks } from './inputs/useCampaignWeeks';
import { GrpTick } from './inputs/useGrpTick';
import { Separate } from './inputs/useSeparate';

import { DateRange } from '@/components/ui/DatePicker.vue';
import { format } from 'date-fns';

interface Create {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
}
interface Select {
  label: string;
  value: number;
}
interface FormValue {
  companyId: Ref<number>;
  durationValues: Ref<DateRange[]>;
  cmProductsValue: Ref<CmSalesImpactProducts | false>;
  reachWeekValue: Ref<Select>;
  reachFrequencyValue: Ref<Select>;
  areaValue: Ref<AreaInfoIdEnum | 'CMSI_ALL'>;
  kantoPerCostValue: Ref<string>;
  kansaiPerCostValue: Ref<string>;
  chukyoPerCostValue: Ref<string>;
  stationPlacementPatternsValue;
  campaignWeeksValue: Ref<Select>;
  grpTickValue: Ref<Select>;
  separateValue: Ref<Select>;
  projectTitle: Ref<string>;
}

interface CreateProject
  extends SimpleArea,
    CmProducts,
    Duration,
    ReachWeek,
    ReachFrequency,
    PerCost,
    StationPlacementPatterns,
    CampaignWeeks,
    GrpTick,
    Separate {
  projectTitle: Ref<string>;
  projectTitleValid: Ref<string>;
  companyId: Ref<number>;
  form: FormValue;
  reachBasicUpdate: (v: { [key: string]: number }) => void;
  create: () => Promise<void>;
  update: () => Promise<void>;
  cancel: () => void;
  initializeCmsiProductInfo: (productId: number) => Promise<void>;
  isLoading: ComputedRef<boolean>;
  isDisabled: Ref<boolean>;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    {
      name: '',
      label: 'CMセールスインパクト'
    },
    {
      name: CMSIS_ROUTES.index,
      label: '売上効果シミュレーター'
    }
  ],
  current: { label: 'プロジェクト新規作成' }
};

export const useCreate = (): Create => {
  const breadcrumbs = ref(BREADCRUMBS);

  return { breadcrumbs };
};

export const useCreateProject = (project: Project): CreateProject => {
  const router = useRouter();
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const projectTitle = ref('');
  const projectTitleValid = ref('');

  // エリア
  const { areaValue, ...area } = useSimpleArea({ init: project.areaName });
  // 商品／ブランド
  const { cmProductsValue, ...cmProducts } = useCmProducts({
    companyId,
    init: project.cmsiProductGroupId
  });
  // 参照期間
  const {
    durationValues,
    durationValids,
    emptyDataValids,
    ...duration
  } = useDuration({
    init: project.periods
  });
  // リーチ基準
  const { reachWeekValue, optionsReachWeek, ...reachWeek } = useReachWeek({
    init: project.reachWeeks
  });
  const {
    reachFrequencyValue,
    optionsReachFrequency,
    ...reachFrequency
  } = useReachFrequency({
    init: project.reachFrequency
  });
  const reachBasicUpdate = (v: { [key: string]: number }) => {
    reachWeekValue.value = optionsReachWeek.value[v.x];
    reachFrequencyValue.value = optionsReachFrequency.value[v.y];
  };
  // パーコスト
  const {
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    perCostValid,
    ...perCost
  } = usePerCost({
    initKanto: project.perCosts?.find(v => v.areaCode === AreaInfoIdEnum.Kanto)
      ?.perCost,
    initKansai: project.perCosts?.find(
      v => v.areaCode === AreaInfoIdEnum.Kansai
    )?.perCost,
    initChukyo: project.perCosts?.find(
      v => v.areaCode === AreaInfoIdEnum.Chukyo
    )?.perCost,
    areaValue
  });
  // 絵柄
  const {
    stationPlacementPatternsValue,
    ...stationPlacementPatterns
  } = useStationPlacementPatterns({ init: project.stationPlacementPatterns });
  // キャンペーン週数
  const { campaignWeeksValue, ...campaignWeeks } = useCampaignWeeks({
    init: project.campaignWeeks
  });
  // GRP上限
  const { grpTickValue, ...grpTick } = useGrpTick({
    init: project.grpTick
  });
  // 区分数
  const { separateValue, ...separate } = useSeparate({
    init: project.numOfDivision
  });

  // edit: 編集の場合Value値を上書き
  if (project) {
    // タイトル
    projectTitle.value = project.cmsisProjectTitle;
  }
  // タイトルバリデーション
  watch(projectTitle, v => {
    if (v.length > 64) {
      projectTitleValid.value = VALIDATION_MESSAGE.max64;
    } else {
      projectTitleValid.value = '';
    }
  });

  const form: FormValue = {
    companyId,
    durationValues,
    cmProductsValue,
    reachWeekValue,
    reachFrequencyValue,
    areaValue,
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    stationPlacementPatternsValue,
    campaignWeeksValue,
    grpTickValue,
    separateValue,
    projectTitle
  };

  // バリデーション
  const { isDisabled } = useValidator({
    areaList: area.areaList,
    areaValue,
    durationValids,
    emptyDataValids,
    cmProductsValue,
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    perCostValid,
    projectTitle,
    projectTitleValid,
    isCreate: !project.cmsisProjectTitle
  });

  const store = useStore();
  const { fetchStore } = store;

  const create = async () => await post(form);

  // _postを実行し画面遷移制御を行うラップ関数
  const postCmSalesImpactSimulation = async (oriform): Promise<void> => {
    const res = await _post(oriform);
    if (res) {
      await router.push({
        name: CMSIS_ROUTES.index
      });
    }
  };

  const update = async () =>
    await put({ cmsisProjectId: project.cmsisProjectId, oriform: form });

  // _putを実行し画面遷移制御を行うラップ関数
  const putCmSalesImpactSimulation = async ({
    cmsisProjectId,
    oriform
  }): Promise<void> => {
    const res = await _put({
      cmsisProjectId: cmsisProjectId,
      oriform: oriform
    });
    if (res) {
      toast({
        title: 'プロジェクトの更新に成功しました',
        variant: 'success'
      });
      await fetchStore({ cmsisProjectId: project.cmsisProjectId });
      await router.push({
        name: CMSIS_ROUTES.index
      });
    }
  };

  const cancel = async () => await router.push({ name: CMSIS_ROUTES.index });

  const [isCreateLoading, post] = useLoading(postCmSalesImpactSimulation);
  const [isUpdateLoading, put] = useLoading(putCmSalesImpactSimulation);

  const isLoading = computed(
    () => isCreateLoading.value || isUpdateLoading.value
  );

  const initializeCmsiProductInfo = async (
    productId: number
  ): Promise<void> => {
    try {
      const { data } = await CmsiApi.getCmSalesImpactProductsProductIdDetails(
        productId,
        companyId.value
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      reachWeekValue.value = optionsReachWeek.value.find(
        v => v.value === data.reachWeeks
      )!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      reachFrequencyValue.value = optionsReachFrequency.value.find(
        v => v.value === data.reachFrequency
      )!;
      const findArea = area.areaList.value.find(v => v.value === data.areaCode);
      areaValue.value = findArea // 一致するエリアがあれば
        ? findArea.value
        : area.areaList.value.length === 1 // なくても、エリアが一つしかなければ
        ? area.areaList.value[0].value
        : 'CMSI_ALL'; // それ以外は全エリア
    } catch (e) {
      console.error(e);
      toast({
        title: '商品情報の取得に失敗しました',
        variant: 'error'
      });
    }
  };

  return {
    areaValue,
    ...area,
    cmProductsValue,
    ...cmProducts,
    durationValues,
    durationValids,
    emptyDataValids,
    ...duration,
    reachWeekValue,
    optionsReachWeek,
    ...reachWeek,
    reachFrequencyValue,
    optionsReachFrequency,
    ...reachFrequency,
    reachBasicUpdate,
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    perCostValid,
    ...perCost,
    stationPlacementPatternsValue,
    ...stationPlacementPatterns,
    campaignWeeksValue,
    ...campaignWeeks,
    grpTickValue,
    ...grpTick,
    separateValue,
    ...separate,
    projectTitle,
    projectTitleValid,
    companyId,
    form,
    isLoading,
    isDisabled,
    create,
    update,
    cancel,
    initializeCmsiProductInfo
  };
};

const _post = async (
  oriform
): Promise<CmSalesImpactSimulatorProject | false> => {
  const form: CmSalesImpactSimulatorProjectForm = createApiParameter(oriform);
  try {
    const { data } = await CmsisApi.postCmSalesImpactSimulatorCmsisProjects(
      form
    );
    return data;
  } catch (e) {
    console.error(e);
    toast({
      title: 'シミュレーションの新規作成に失敗',
      variant: 'error'
    });
    return false;
  }
};

const _put = async ({
  cmsisProjectId,
  oriform
}): Promise<CmSalesImpactSimulatorProject | false> => {
  const form: CmSalesImpactSimulatorProjectForm = createApiParameter(oriform);
  try {
    const {
      data
    } = await CmsisApi.putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(
      cmsisProjectId,
      form
    );
    return data;
  } catch (e) {
    console.error(e);
    toast({
      title: 'シミュレーションの更新に失敗',
      variant: 'error'
    });
    return false;
  }
};

const createApiParameter = (oriform): CmSalesImpactSimulatorProjectForm => {
  const areaConfigs = {
    CMSI_ALL: ['Kanto', 'Kansai', 'Chukyo'],
    SAAS_KANTO: ['Kanto'],
    SAAS_KANSAI: ['Kansai'],
    SAAS_CHUKYO: ['Chukyo']
  };

  const areaToPerCost = area => ({
    areaCode: AreaInfoIdEnum[area],
    perCost: parseFloat(
      oriform[`${area.toLowerCase()}PerCostValue`].value.replace(/,/g, '')
    )
  });

  return {
    cmsiProductGroupId: oriform.cmProductsValue.value.productId,
    title: oriform.projectTitle.value,
    periods: oriform.durationValues.value.map(v => {
      return {
        startDate: format(v.start, 'yyyy-MM-dd'),
        endDate: format(v.end, 'yyyy-MM-dd')
      };
    }),
    reachWeeks: oriform.reachWeekValue.value.value,
    reachFrequency: oriform.reachFrequencyValue.value.value,
    areaCode: oriform.areaValue.value,
    averagePerCost: 0, // 平均パーコストは使用しないため0を設定
    stationPlacementPatterns: oriform.stationPlacementPatternsValue.value,
    campaignWeek: oriform.campaignWeeksValue.value.value,
    grpCap: oriform.grpTickValue.value.value,
    numOfDivision: oriform.separateValue.value.value,
    perCosts: areaConfigs[oriform.areaValue.value].map(areaToPerCost)
  };
};

const useValidator = props => {
  const {
    areaValue,
    areaList,
    durationValids,
    emptyDataValids,
    cmProductsValue,
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    perCostValid,
    projectTitle,
    projectTitleValid,
    isCreate
  } = props;

  const perCostValidator = () => {
    if (areaValue.value === 'CMSI_ALL') {
      const res = areaList.value.map(v => {
        if (v.value === 'CMSI_ALL') {
          return false; // CMSI_ALLは無視
        } else if (v.value === AreaInfoIdEnum.Kanto) {
          return Boolean(perCostValid.value) || !kantoPerCostValue.value;
        } else if (v.value === AreaInfoIdEnum.Kansai) {
          return Boolean(perCostValid.value) || !kansaiPerCostValue.value;
        } else if (v.value === AreaInfoIdEnum.Chukyo) {
          return Boolean(perCostValid.value) || !chukyoPerCostValue.value;
        }
      });
      return res.includes(true);
    }
    if (areaValue.value === AreaInfoIdEnum.Kanto)
      return Boolean(perCostValid.value) || !kantoPerCostValue.value;
    if (areaValue.value === AreaInfoIdEnum.Kansai)
      return Boolean(perCostValid.value) || !kansaiPerCostValue.value;
    if (areaValue.value === AreaInfoIdEnum.Chukyo)
      return Boolean(perCostValid.value) || !chukyoPerCostValue.value;
  };

  const isDisabled = ref(isCreate || perCostValidator());
  const valids = ref({
    durations: isCreate,
    emptyData: false,
    cmProduct: isCreate,
    perCostValid: isCreate || perCostValidator(),
    projectTitleValid: false,
    projectTitle: isCreate
  });

  watch(cmProductsValue, v => {
    valids.value.cmProduct = !v;
  }); // 商品／ブランド
  watch(
    emptyDataValids,
    v => {
      valids.value.emptyData = v.includes(true);
    },
    { deep: true }
  ); // 空データがある期間
  watch(durationValids, v => {
    valids.value.durations = v.some(j => Boolean(j));
  }); // 期間
  watch(
    [
      perCostValid,
      kantoPerCostValue,
      kansaiPerCostValue,
      chukyoPerCostValue,
      areaValue
    ],
    () => {
      valids.value.perCostValid = perCostValidator();
    }
  ); // 平均パーコスト
  watch(projectTitle, v => {
    valids.value.projectTitle = !v;
  }); // タイトル
  watch(projectTitleValid, v => {
    valids.value.projectTitleValid = Boolean(v);
  }); // タイトル

  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ
  return {
    isDisabled
  };
};
