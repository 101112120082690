import { Ref, ref } from 'vue';
import { CmsiApi } from '@/api';
import { CmSalesImpactProducts } from '@/api/openapi';

import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import { requestCanceler } from '@/plugins/axiosCancel';

import useLoading from '@/composables/loading';

// 商品／ブランド
export interface CmProducts {
  isLoadingCmProducts: Ref<boolean>;
  cmProductsValue: Ref<CmSalesImpactProducts | false>;
  cmProductsList: Ref<CmSalesImpactProducts[]>;
}

export const useCmProducts = ({
  companyId,
  init
}: {
  companyId: Ref<number>;
  init: number;
}): CmProducts => {
  const cmProductsValue = ref<CmSalesImpactProducts | false>(false);
  const cmProductsList = ref([] as CmSalesImpactProducts[]);

  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    if (companyId.value) {
      await fetch(companyId.value).then(res => {
        cmProductsList.value = res.map(v => ({
          ...v,
          $isDisabled: !v.isAvailableWeeklyReport,
          icon: !v.isAvailableWeeklyReport ? 'exclamation' : ''
        }));
        // 初期化
        if (init) {
          const current = res.filter(v => v.productId == init);
          if (current[0]) {
            cmProductsValue.value = current[0];
          } else {
            toast({
              title: '商品一覧と一致しません',
              variant: 'error'
            });
          }
        }
      });
    }
  })();

  return {
    isLoadingCmProducts: isLoading,
    cmProductsValue,
    cmProductsList
  };
};

const _fetch = async (companyId): Promise<CmSalesImpactProducts[]> => {
  try {
    const { data } = await CmsiApi.getCmSalesImpactProducts(companyId, {
      cancelToken: requestCanceler.token()
    });
    return data;
  } catch (e) {
    if (e.state === httpCode.forbidden) {
      toast({
        title: '商品データ取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    return [] as CmSalesImpactProducts[];
  }
};
