import { computed, ref, Ref } from 'vue';
import { AreaInfoIdEnum } from '@/api/openapi/index';

export interface PerCost {
  kantoPerCostValue: Ref<string>;
  kansaiPerCostValue: Ref<string>;
  chukyoPerCostValue: Ref<string>;
  perCostValid: Ref<string>;
}

export const usePerCost = ({
  initKanto,
  initKansai,
  initChukyo,
  areaValue
}: {
  initKanto?: number;
  initKansai?: number;
  initChukyo?: number;
  areaValue: Ref<AreaInfoIdEnum | 'CMSI_ALL'>;
}): PerCost => {
  const kantoPerCostValue = ref('');
  const kansaiPerCostValue = ref('');
  const chukyoPerCostValue = ref('');
  const validator = value =>
    parseFloat(value.replace(/,/g, '')) < 10000 ||
    parseFloat(value.replace(/,/g, '')) > 500000;
  const validationMessage = '1万~50万の値で入力してください';
  const perCostValid = computed(() => {
    if (areaValue.value === 'CMSI_ALL') {
      const areaName: Array<string> = [];
      if (validator(kantoPerCostValue.value)) areaName.push('関東');
      if (validator(kansaiPerCostValue.value)) areaName.push('関西');
      if (validator(chukyoPerCostValue.value)) areaName.push('中京');
      if (areaName.length !== 0)
        return areaName.join('・') + 'の値は' + validationMessage;
    }
    if (areaValue.value === AreaInfoIdEnum.Kanto) {
      if (validator(kantoPerCostValue.value)) return validationMessage;
    }
    if (areaValue.value === AreaInfoIdEnum.Kansai) {
      if (validator(kansaiPerCostValue.value)) return validationMessage;
    }
    if (areaValue.value === AreaInfoIdEnum.Chukyo) {
      if (validator(chukyoPerCostValue.value)) return validationMessage;
    }
    return '';
  });

  if (initKanto) {
    kantoPerCostValue.value = initKanto.toString();
  }
  if (initKansai) {
    kansaiPerCostValue.value = initKansai.toString();
  }
  if (initChukyo) {
    chukyoPerCostValue.value = initChukyo.toString();
  }

  return {
    kantoPerCostValue,
    kansaiPerCostValue,
    chukyoPerCostValue,
    perCostValid
  };
};
