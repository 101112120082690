import { ref } from 'vue';
import { defineStore } from 'pinia';
import { CmsisApi } from '@/api';
import { CmSalesImpactSimulatorCondition } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';

export interface Project extends CmSalesImpactSimulatorCondition {
  cmsisProjectId: number;
}

export const useStore = defineStore('cmsis', () => {
  const project = ref({} as Project);
  const isLoading = ref(true as boolean);

  async function fetchStore({ cmsisProjectId }: { cmsisProjectId: number }) {
    // 商品の取得
    isLoading.value = true;
    try {
      const {
        data
      } = await CmsisApi.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(
        cmsisProjectId
      );
      project.value = { cmsisProjectId, ...data };
    } catch (e) {
      if (e.status === httpCode.forbidden) return;
      toast({
        title: '商品情報の取得に失敗',
        variant: 'error'
      });
    } finally {
      isLoading.value = false;
    }
  }

  return {
    project,
    isLoading,
    fetchStore
  };
});
