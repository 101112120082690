import { Ref, ref, computed, ComputedRef } from 'vue';

import { DATE_FORMAT } from '@/common/format';
import { format, startOfDay } from 'date-fns';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { Period } from '@/api/openapi';

// 期間
export const formatDuration = (
  durationValue: Ref<DateRange | undefined>
): string[] => {
  if (durationValue.value) {
    return [
      format(durationValue.value.start ?? new Date(), DATE_FORMAT),
      format(durationValue.value.end ?? new Date(), DATE_FORMAT)
    ];
  }
  return [];
};

export interface DurationOptions {
  minDate: Date;
  maxDate: Date;
  Valid: string[];
  endDateBOfWValid: string;
}

export interface Duration {
  durationMax: number;
  durationSelect: Ref<DateRange>;
  durationValues: Ref<DateRange[]>;
  durationsOptions: Ref<DurationOptions>;
  addDuration: (DateRange) => void;
  removeDuration: (DateRange) => void;
  initDuration: (DateRange) => void;
  durationValids: ComputedRef<(string | boolean)[]>;
  emptyDataValids: Ref<boolean[]>;
}

export const useDuration = ({ init }: { init: Period[] }): Duration => {
  const durationsOptions = ref({} as DurationOptions);
  const durationValues = ref([] as DateRange[]);
  const max = 5;
  const durationSelect = ref({} as DateRange);
  const emptyDataValids = ref([] as boolean[]);

  // 初期化
  if (init) {
    durationValues.value = init.map(v => ({
      start: new Date(v.startDate),
      end: new Date(v.endDate)
    }));
  }

  const addDuration = (props: DateRange) => {
    if (durationValues.value.length < max) {
      durationValues.value.push(props);
      durationSelect.value = props;
    }
  };
  const removeDuration = (duration: DateRange) => {
    if (durationValues.value.length > 1) {
      const i = durationValues.value.findIndex(v => v === duration);
      durationValues.value.splice(i, 1);
    }
    if (durationSelect.value === duration) {
      durationSelect.value = {} as DateRange;
    }
  };
  const initDuration = (props: DateRange) => {
    addDuration(props);
    durationSelect.value = durationValues.value[0];
  };

  const durationValids = computed(() => {
    return durationValues.value.map((v, i) => {
      if (!v.start || !v.end) {
        return '日付を入力してください';
      }
      const start = startOfDay(v.start as Date);
      const end = startOfDay(v.end as Date);
      if (start > end) {
        return '終了日は開始日より後にしてください';
      }

      // 期間の重複
      const overlaps = durationValues.value.map((e, k) => {
        if (k === i) {
          return null;
        }
        if (start && end && e.start && e.end) {
          const maxStart = new Date(
            Math.max.apply(null, [
              start.getTime(),
              startOfDay(e.start).getTime()
            ])
          );
          const minEnd = new Date(
            Math.min.apply(null, [end.getTime(), startOfDay(e.end).getTime()])
          );

          if (maxStart > minEnd) {
            return false;
          } else {
            return k + 1;
          }
        }
      });
      const unique = overlaps.filter(v => v);
      if (unique.length > 0) {
        return `期間${unique.join(',')}が重複しています。`;
      }

      return false;
    });
  });

  return {
    durationSelect,
    durationMax: max,
    durationValues,
    durationsOptions,
    addDuration,
    removeDuration,
    initDuration,
    durationValids,
    emptyDataValids
  };
};
