import { Ref, ref } from 'vue';
interface Select {
  label: string;
  value: number;
}

export interface Separate {
  separateValue: Ref<Select>;
  separateOptions: Ref<Select[]>;
}

export const useSeparate = ({ init }: { init: number }): Separate => {
  const separateValue = ref({} as Select);
  const separateOptions = ref([] as Select[]);
  separateOptions.value = [...Array(3)].map((_, i) => ({
    label: `${(i + 1) * 5 + 5}区分`,
    value: (i + 1) * 5 + 5
  }));
  // 初期化
  separateValue.value = init
    ? separateOptions.value.filter(v => v.value === init)[0]
    : separateOptions.value[0];
  return {
    separateValue,
    separateOptions
  };
};
